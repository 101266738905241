<template>
  <div class="wheelOfFortune_wrap">
    <!-- <div class="wheelOfFortune_logo">
      <img
        src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/weiye-logo-093020200930.svg"
        alt="加微信抢红包-国家标准物质网"
      />
    </div> -->
    <div class="wheelOfFortune_title" style="padding-top: 20px;">
      <img
        src="@/assets/img/liveRoom/redrain1.png"
        alt="加微信抢红包-国家标准物质网"
      />

    </div>
    <div class="wheelOfFortune_middle">
      <ul class="lottery">
        <li class="wheelOfFortune_li_cash" :class="{'wheelOfFortune_li_cash': true, 'active': activeNum == 0}">
            <img
              style="width: 25px;height: 25px;"
              src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png"
              alt="加微信抢红包-谢谢惠顾"
            />
            <div>谢谢惠顾</div>
        </li>
        <li class="wheelOfFortune_li_cash" :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 1}">
            <img
                src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png"
                alt="加微信抢红包-随机红包"
            />
            <div>
                <span>166.88—188.88</span>
                <p>随机红包</p>
            </div>
        </li>
        <li class="wheelOfFortune_li_cash" :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 2}">
            <img
              style="width: 25px;height: 25px;"
              src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png"
              alt="加微信抢红包-谢谢惠顾"
            />
            <div>谢谢惠顾</div>
        </li>
        <li class="wheelOfFortune_li_cash" :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 3}">
            <img
                    src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png"
                    alt="加微信抢红包-随机红包"
            />
            <div>
                <span>1.68-6.88元</span>
                <p>随机红包</p>
            </div>
        </li>
        <li class="wheelOfFortune_li_cash" :class="{'wheelOfFortune_li_cash': true, 'active': activeNum == 4}">
            <img
              style="width: 25px;height: 25px;"
              src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png"
              alt="加微信抢红包-谢谢惠顾"
            />
            <div>谢谢惠顾</div>
        </li>
        <li class="wheelOfFortune_li_cash" :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 5}">
            <img
                    src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png"
                    alt="加微信抢红包-随机红包"
            />
            <div>
                <span>66.88-88.88元</span>
                <p>随机红包</p>
            </div>
        </li>
        <li class="wheelOfFortune_li_cash" :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 6}">
            <img
              style="width: 25px;height: 25px;"
              src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png"
              alt="加微信抢红包-谢谢惠顾"
            />
            <div>谢谢惠顾</div>
        </li>
        <li class="wheelOfFortune_li_cash" :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 7}">
            <img
                    src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png"
                    alt="加微信抢红包-随机红包"
            />
            <div>
                <span>6.88-20.88元</span>
                <p>随机红包</p>
            </div>
        </li>
        <li class="wheelOfFortune_li_luckDraw"  @click="handleLuckDraw" >
            <div>点击领取</div>
        </li>
      </ul>
    </div>
    <div class="wheelOfFortune_bottom">
      <span @click="back">返回直播间</span>
      <!-- <p class="wheelOfFortune_bottom_line"></p>
      <div class="wheelOfFortune_bottom_wrap">
        <div class="wheelOfFortune_bottom_title">活动说明</div>
        <div class="wheelOfFortune_bottom_content">
          <p>1. 本次活动对象仅限关注伟业计量手机端下单用户。</p>
          <p>2. 本次活动2021年11月01日起长期开展。</p>
          <p>3. 本次活动每个手机号每周仅可抽取一次。</p>
          <p>4. 红包将自动发放到微信账户中，请在微信"钱包"内查看。</p>
          <p>注：本活动最终解释权归伟业计量所有</p>
        </div>
      </div> -->
    </div>
    <div class="wheelOfFortune_bottom_img">
      <img src="@/assets/img/whellOfFortune/bottom.png" alt="加微信抢红包-底部图片" />
    </div>
<!--    <div class="wheelOfFortune_dialog" v-if="dialogShow">-->
<!--        <div class="wheelOfFortune_dialog_yy" @click="handleDialog"></div>-->
<!--        <div class="wheelOfFortune_dialog_content" v-if="redPrice && redPrice>0">-->
<!--            <img class="close" @click="handleDialog" src="@/assets/img/close.png" alt="">-->
<!--            <p>恭喜获得红包-->
<!--                <label class="prize_text">{{ redPrice }}<span style="font-size:14px;">元</span></label>-->
<!--            </p>-->
<!--            <span class="wx_span">请在公众号中领取</span>-->
<!--        </div>-->
<!--        <div class="fail-win" v-else @click="handleDialog">-->
<!--            <div>很遗憾</div>-->
<!--            <div>离红包只差一点点~</div>-->
<!--            <div>-->
<!--              <span>我知道了</span>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <teleport to="body">
      <div class="modal" v-show="visiblePic">
        <div class="modal-header">温馨提示</div>
        <div class="modal-content">
          <div style="text-align: left;margin-bottom: 10px;text-align: center;">
            请长按关注公众号再抢红包<br/>
            红包将通过公众号实时兑现<br/>
            关注完成后，请返回直播间重新进入红包雨参与
          </div>
          
          <img style="width: 100%;height: 100%;" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Yunan/weiye_erweima20200814.jpg" alt="">
        </div>
        <div class="modal-footer">
          <div @click="closePic">取消</div>
        </div>
      </div>
      <div v-show="visiblePic" class="toast-modal"></div>
    </teleport>
  </div>
    <div class="modal1" id="modal1" v-if="showModal">
        <div class="modal-backdrop"></div>
        <div class="modal-body">
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/faa59b1650dbacb64250045ea371df39.png" class="modal-close" @click="closeModal()">
          <div v-if="showRed">
            <p>
              恭喜您获得红包
            </p>
            <p>
              <span class="modal-price">{{price}}</span> 元
            </p>
            <p>
              请在公众号中领取
            </p>
          </div>
          <div v-else class="modal-showM">
            {{Message}}
          </div>
            <ul>
                <li v-for="(item,index) in rushList" :key="index">恭喜 <span>{{item.mobile}}</span> 抢到{{item.redPacketMoney}}元红包</li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref, inject, onMounted, reactive} from "vue";
import axios from "@/api/axios";
import api from "@/api/axios";
import qs from "qs";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import { useRouter, useRoute } from "vue-router";
import $ from "jquery";
export default defineComponent({

  name: "WechatRed",
    data() {
        return {
            Message: '',
            showRed:false,
            price:0,
            rushList: [
            ],
            isGetLog:false
        }
    },
  setup(){
    const route = useRoute();
    let code = route.query.code as string
    let state = route.query.state as string
    const courseId = route.query.courseId
    const visiblePic = ref(false)
    const showModal = ref(false);

    const href = window.location.href
    const codeReg = /code=(.*?)&/
    const stateReg = /state=(.*)/

    if (href.match(codeReg)) {
        code = RegExp.$1
    }
    if (href.match(stateReg)) {
        state = RegExp.$1
    }
    const router = useRouter();
    const arr = [1,2,3,4,5,6,7,8];
    let i = 0;//转到哪个位置
    let count= 0;//转圈初始值
    const totalCount = 3;//转动的总圈数
    let speed = 300;//转圈速度，越大越慢
    const minSpeed = 300;
    let timer: any = null;
    let isClick = false;
    let index = 3;//指定转到哪个奖品
    const text = ref("100元代金券");//奖品
    const activeNum = ref(0);
    const imgUrl = ref("https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/cash_10020210113.png");
    const imgStyle = ref({
      width: "1rem",
      left: "1.46rem",
      top: "1.5rem",
    });
    const imgUrlArr = [
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/cash_10020210113.png",
        text: "100元代金券"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "100元红包"
      },
      {
        img:  "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
        text: "168积分"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "2.88元红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/cash_20020210113.png",
        text: "200元代金券"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "66.88元红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
        text: "888积分"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
        text: "10.00元红包"
      },
    ]
    const imgArr = [
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/cash_10020210113.png",
            width: "1rem",
            left: "1.46rem",
            top: "1.5rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/cash_20020210113.png",
            width: "1rem",
            left: "1.46rem",
            top: "1.5rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
            width: "0.7rem",
            left: "1.63rem",
            top: "1.4rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/redpacket20210113.png",
            width: "0.7rem",
            left: "1.63rem",
            top: "1.4rem",
        }
    ]
    const dialogShow = ref(false);
    let that: any = null;
    const redId = [
      {
        id: 1,
        index: 1
      },
      {
        id: 2,
        index: 2
      },
      {
        id: 3,
        index: 3
      },
      {
        id: 4,
        index: 4
      },
      {
        id: 5,
        index: 5
      },
      {
        id: 6,
        index: 6
      },
      {
        id: 7,
        index: 7
      },
      {
        id: 8,
        index: 8
      },
    ];


    function roll(){
        //速度衰减
        speed -= 50;
        if(speed<=10){
            speed=10;
        }
        //每次调用都去掉全部active类名
        activeNum.value = 10;
        i++;
        //计算转圈次数
        if(i >= 8){
            i = 0;
            count++;
        }
        activeNum.value = i;
        //满足转圈数和指定位置就停止
        if (count >= totalCount && (i + 1) == index){
            clearTimeout(timer);
            speed = minSpeed;
            text.value = imgUrlArr[i].text;
            imgUrl.value = imgUrlArr[i].img;
            isClick = true;
            for (let j = 0; j < imgArr.length; j++) {
                if (imgArr[j].img == imgUrl.value) {
                  imgStyle.value = {
                    width: imgArr[j].width,
                    left: imgArr[j].left,
                    top: imgArr[j].top,
                  }
                }
            }
              showModal.value=true;
        }else{
            timer = setTimeout(roll,speed);//不满足条件时调用定时器
            //最后一圈减速
            if(count >= totalCount-1 || speed <= 50){
                speed += 100;
            }
        }
    }
    function closeModal() {
          showModal.value = false
      }
    function handleDialog(){
      dialogShow.value = false;
    } 
    function handleLuckDraw() {
        //更新红包记录
        api.post("/M/CourseServer/getOrderingRedPacketRecord", qs.stringify({courseId})).then((res) => {
            if(res.data.success){
                this.rushList = res.data.data;
            }else {
                this.Message=res.data.msg;
            }
            //只请求一次红包接口
            if(!this.isGetLog){
                this.isGetLog=true;
                api.post('/M/CourseServer/doOrderingRedPacket',
                    qs.stringify({
                        code,
                        state,
                        courseId,
                    })
                ).then((res) => {
                    const data = res.data;
                    if (data.success) {
                        this.showRed=true;
                        if (data.msg == 'noFollow') {
                            Toast({
                                title: '您还未关注伟业计量微信公众号，请先关注再抽奖！',
                                type: "error",
                                duration: 2000
                            });
                            visiblePic.value = true;
                            return false
                        }
                        redId.forEach(item => {
                            if (item.id == data.data.rewardNum) {
                                index = item.index
                            }
                        });
                        //转盘结束展示抽奖提示框
                        roll();
                        // redPrice.value = data.link;
                        //显示抽中对应的金额
                        this.price=data.link;
                    } else {
                        //没有抽成功显示msg
                        this.showRed=false;
                        this.Message=data.msg;
                        showModal.value=true;
                    }
                }).catch((err) => {
                        console.log(err);
                        showModal.value=true;
                    });
            }else {
                //若已抽奖则只用展示抽奖提示框
                showModal.value=true;
            }
        }).catch((err) => {
            this.Message=err.message
            showModal.value=true;
        });
    }


    function closePic() {
      visiblePic.value = false
    }

    onMounted(() => {
      $('.wheelOfFortune_wrap').height(($(window) as any).height()-70)
    })
    function back() {
      router.go(-1);
    }

    return {
      text,
      imgUrl,
      handleLuckDraw,
      imgStyle,
      activeNum,
      dialogShow,
      handleDialog,
      back,
      visiblePic,
      closePic,
      showModal,
      closeModal,
    }
  }
});
</script>

<style lang="scss" scoped>
    .modal-close {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 0;
        top: 0;
        border-radius: 50%;
        border: 0;
    }
    .modal-showM{
      margin: 80px auto 135px;
      text-align: center;
      font-size: 16px;
      color: white;
    }

    .modal-backdrop {
        /* 当打开模态框时，用半透明的蒙层遮挡网页剩余内容 */
        position: fixed;
        z-index: 99;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);

    }

    .modal-body p {
        font-size: 16px;
        text-align: center;
        color: white;
        margin: 10px 0 ;
    }
    .modal-price{
        font-size: 40px;
    }
    .modal-body {
        /* 给模态框的主体定位 */
        background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/d1eef8f50910535ef397fbd0f3811c52.png");
        background-size: 100% 100%;
        border-radius: 8px;
        z-index: 99;
        width: 320px;
        height: 400px;
        position: fixed;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 20px 5px;
        overflow: auto;
        font-size: 14px;
    }

    .modal-body ul {
        text-align: center;
        font-size: 12px;
        margin-top: 115px;
        height: 100px;
        overflow-y: auto;
    }

    .modal-body ul li {
        margin: 8px 0;
        color: white;
    }


    .wheelOfFortune_wrap{
  img{
    vertical-align: middle;
  }
}
.wheelOfFortune_wrap {
  width: 100%;
  height: 100%;
  background: url("../assets/img/whellOfFortune/background.png") no-repeat;
  background-size: 100%;
  background-color: rgb(221, 0, 36);
  position: relative;
}

.wheelOfFortune_logo {
  text-align: center;
  padding-top: 17px;
}

.wheelOfFortune_logo img {
  // width: 92px;
  width: 60px;
  // width: 1.5rem;
}

.wheelOfFortune_title {
  text-align: center;
  margin-top: 10px;
}

.wheelOfFortune_title img {
  width: 182px;
}

.wheelOfFortune_middle {
  width: 261px;
  height: 221.5px;
  background: url("../assets/img/whellOfFortune/middleBg.png") no-repeat;
  background-size: 100%;
  margin: 0 auto;
  margin-top: 20px;
  box-sizing: content-box;
}

.wheelOfFortune_middle ul {
  width: 100%;
  height: 100%;
  padding: 13px 12.5px;
  position: relative;
}

.wheelOfFortune_middle ul li {
  width: 76px;
  height: 63px;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  background: url("../assets/img/whellOfFortune/cashBtn.png") no-repeat;
  background-size: cover;
  box-sizing: border-box;
  float: left;
  margin: 1px;
}

.wheelOfFortune_middle ul li p {
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.8);
  width: 125%;
  line-height: 12.5px;
  font-size: 12px;
}

// .wheelOfFortune_li_cash {
//   padding-top: 5px;
// }

.wheelOfFortune_li_cash img {
  width: 25px;
  height: 25px;
  position: relative;
  top: -5px;
}

.wheelOfFortune_li_cash div {
  margin-top: -8px;
}

.wheelOfFortune_li_cash div,
.wheelOfFortune_li_redpacket div,
.wheelOfFortune_li_integral div {
  color: rgb(167, 34, 5);
  font-weight: bold;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.8);
  width: 125%;
  line-height: 12.5px;
  font-size: 10px;
}

.wheelOfFortune_li_redpacket div{
  margin-top: -10px;
}

.wheelOfFortune_li_luckDraw {
  position: relative;
}

.wheelOfFortune_li_luckDraw div {
  position: absolute;
  font-size: 20px;
  color: rgb(233, 20, 12);
  font-weight: bold;
  width: 60%;
  top: 5%;
  left: 21%;
  line-height: 25px;
}

.wheelOfFortune_li_redpacket img {
  // width: 27.5px;
  // height: 25.5px;
  width: 25px;
  height: 25px;
  position: relative;
  top: -5px;
}

// .wheelOfFortune_li_integral {
//   padding-top: 7px;
// }

.wheelOfFortune_li_integral div {
  margin-top: 3px;
}

.wheelOfFortune_li_integral img {
  width: 31.5px;
}

.wheelOfFortune_middle li.active {
  border: 3px solid #45fb27;
}

.wheelOfFortune_bottom {
  margin-top: 20px;
  padding-bottom: 75px;
  background: rgb(221, 0, 36);

  text-align: center;
  margin-top: 20%;
}

.wheelOfFortune_bottom span {
  font-size: 17px;
  font-weight: bold;
  color: #D80224;
  padding: 12px 20%;
  background: #fff;
  border-radius: 45px;
}

.wheelOfFortune_bottom_line {
  width: 300px;
  height: 5.5px;
  background: rgb(234, 33, 7);
  border-radius: 10px;
  border: 1px solid rgb(234, 104, 162);
  margin: 0 auto;
}

.wheelOfFortune_bottom_wrap {
  width: 280px;
  margin: 0 auto;

  background-color: #f66259;
  padding: 0 10px;
}

.wheelOfFortune_bottom_title {
  width: 100%;
  height: 20px;
  background: rgb(246, 98, 89);
  color: rgb(248, 222, 30);
  font-size: 15px;
  text-align: center;
}

.wheelOfFortune_bottom_content {
  width: 100%;
  padding: 10px 0;
  color: #fff;
  font-size: 14px;
}

.wheelOfFortune_bottom_img img {
  width: 100%;
}

.wheelOfFortune_middle ul li:nth-child(1) {
  position: absolute;
  display: block;
  left: 13px;
  top: 12.5px;
}

.wheelOfFortune_middle ul li:nth-child(2) {
  position: absolute;
  left: 91px;
  top: 12.5px;
}

.wheelOfFortune_middle ul li:nth-child(3) {
  position: absolute;
  left: 169px;
  top: 12.5px;
}

.wheelOfFortune_middle ul li:nth-child(4) {
  position: absolute;
  left: 169px;
  top: 77.5px;
}

.wheelOfFortune_middle ul li:nth-child(8) {
  position: absolute;
  left: 13px;
  top: 77.5px;
}

.wheelOfFortune_middle ul li:nth-child(7) {
  position: absolute;
  left: 13px;
  top: 142.5px;
}

.wheelOfFortune_middle ul li:nth-child(6) {
  position: absolute;
  left: 91px;
  top: 142.5px;
}

.wheelOfFortune_middle ul li:nth-child(5) {
  position: absolute;
  left: 169px;
  top: 142.5px;
}

.wheelOfFortune_middle ul li:nth-child(9) {
  position: absolute;
  left: 91px;
  top: 77.5px;
  img{
    max-width: 100%;
  }
}

.wheelOfFortune_bottom_img {
  position: fixed;
  bottom: 0;
  left: 0;
}


.wheelOfFortune_dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.wheelOfFortune_dialog_content {
  width: 194px;
  height: 270px;
  position: absolute;
  left: 50%;
  top: 50%;
  // margin-left: -97px;
  // margin-top: -175px;
  background: url(../assets/img/whellOfFortune/dialog.png) no-repeat;
  background-size: contain;
  background-color: #fff;
  border-radius: 10px;
}

.wheelOfFortune_dialog_yy,
.wheelOfFortune_wechat_yy {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.prize {
  position: absolute;
  width: 50px;
  left: 73px;
  top: 75px;
}

.wheelOfFortune_dialog_bottom {
  margin-top: 160px;
  text-align: center;
}

.wheelOfFortune_dialog_bottom p {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
}

.wheelOfFortune_dialog_bottom span {
  display: inline-block;
  width: 119px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  margin-top: 15px;
  background: rgb(255, 242, 59);
  box-shadow: 0px 3px 0px rgba(255, 169, 57, 1);
  -moz-box-shadow: 0px 3px 0px rgba(255, 169, 57, 1);
  -webkit-box-shadow: 0px 3px 0px rgba(255, 169, 57, 1);
  background: linear-gradient(to bottom, rgb(255, 249, 69), rgb(255, 198, 0));
  background: -moz-linear-gradient(to bottom, rgb(255, 249, 69), rgb(255, 198, 0));
  background: -webkit-linear-gradient(to bottom, rgb(255, 249, 69), rgb(255, 198, 0));
  color: rgb(255, 78, 0);
  font-weight: bold;
  font-size: 12px;
}

.wheelOfFortune_dialog_span {
  display: inline-block;
  width: 50%;
  height: 20px;
  position: absolute;
  top: 117.5px;
  left: 50px;
  background: rgb(255, 63, 49);
}

.wheelOfFortune_wechat {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.wheelOfFortune_wechat_title {
  font-size: 20px;
  color: #333;
}

.wheelOfFortune_wechat_title p {
  text-align: center;
}

.wheelOfFortune_wechat_content {
  width: 80%;
  background: #fff;
  position: absolute;
  text-align: center;
  padding: 15px;
  left: 10%;
  top: 20%;
}

.wheelOfFortune_wechat_content img {
  width: 250px;
}

.wheelOfFortune_wechat_active {
  display: none;
}

.wheelOfFortune_dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.wheelOfFortune_dialog_yy,
.wheelOfFortune_wechat_yy {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.wheelOfFortune_dialog_content {
    width: 515px;
    height: 437px;
    position: absolute;
    left: 52%;
    top: 50%;
    background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/831cada17973da1c11c1b98ec0b3b824.png") no-repeat;
    background-size: contain;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    position: relative;
}

.wheelOfFortune_dialog_content .close {
  width: 32px;
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(400%);
  z-index: 100;
}


.wheelOfFortune_dialog_content p{
    position: absolute;
    left: 35%;
    top: 50%;
    color: #fff;
    font-size: 15px;

}

.wheelOfFortune_dialog_content p label{
    display: block;
    margin-top: 8px;
    text-align: center;
    font-size: 28px;
}


.wheelOfFortune_dialog_content span.wx_span{
    position: absolute;
    left: 37%;
    top: 64%;
    color: #F5C74E;
    font-size: 14px;
}

.fail-win {
  width: 80%;
  height: 180px;
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  left: 10%;
  top: 35%;
  align-items: center;
  padding: 30px;

}
.fail-win > div {
  width: 100%;
  text-align: center;
  font-size: 14px;
}
.fail-win > div:first-child {
  font-size: 17px;
  font-weight: bold;
}
.fail-win > div:nth-child(2) {
  color: #737373;
}
.fail-win > div:last-child {
  margin-top: 20px;
  span {
    padding: 8px 50px;
    background: #E00024;
    color: #fff;
    border-radius: 45px;
  }
}

.modal {
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  color: #333;
  padding: 10px 20px;
  min-height: 50px;
  max-width: 340px;
  width: 80%;
  font-size: 14px;
  box-sizing: border-box;
  .modal-header {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    text-align: center;
  }
  .modal-content {
    padding: 15px 0;
    line-height: 24px;
    text-align: center;
  }
  .modal-footer {
    display: flex;
    line-height: 40px;
    div {
      flex: 1;
      text-align: center;

      background-color: #d64b4b;
      border-radius: 5px;
      color: #fff;
    }
    .confirm {
      color: #df0024;
    }
  }
  img {
    width: 30px;
    height: 30px;

    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
  }
}
</style>
